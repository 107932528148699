// Build information, automatically generated by `ng-info`
const build = {
    version: "0.0.0",
    timestamp: "Thu Sep 12 2024 11:22:53 GMT+0000 (Coordinated Universal Time)",
    message: null,
    git: {
        user: null,
        branch: "HEAD",
        hash: "f4c694",
        fullHash: "f4c69403cca62840a561574a2d513bd6f96baa5a"
    }
};

export default build;