import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { environment } from "../../environments/environment";
import { ErrorHandlerService } from "./response-utils.service";
import { Customer } from "../model/customer";
import { catchError } from "rxjs/operators";

@Injectable()
export class TenantService {
  private URL: string = environment.apiUrl + "/tenant";

  constructor(
    private http: HttpClient,
    private errorHandler: ErrorHandlerService
  ) {}

  getTenantlist(): Observable<Customer[]> {
    let url = this.URL + "/getAllTenant";
    return this.http
      .get(url)
      .pipe(
        catchError((error: HttpErrorResponse) =>
          this.errorHandler.errorHandler(error)
        )
      );
  }

  toggleTenantStatus(id: number) {
    return this.http
      .put(this.URL + "/toggle/" + id, {})
      .pipe(
        catchError((error: HttpErrorResponse) =>
          this.errorHandler.errorHandler(error)
        )
      );
  }

  getTenantById(id: number) {
    return this.http
      .get(this.URL + "/getById/" + id)
      .pipe(
        catchError((error: HttpErrorResponse) =>
          this.errorHandler.errorHandler(error)
        )
      );
  }

  checkCustomerName(name: String) {
    let urlSort = this.URL + "/getByName";
    return this.http
      .get(urlSort + "?name=" + name)
      .pipe(
        catchError((error: HttpErrorResponse) =>
          this.errorHandler.errorHandler(error)
        )
      );
  }

  checkTenantKey(tenantKey: string) {
    let urlSort = this.URL + "/getByTenantKey";
    return this.http
      .get(urlSort + "?tenantKey=" + tenantKey)
      .pipe(
        catchError((error: HttpErrorResponse) =>
          this.errorHandler.errorHandler(error)
        )
      );
  }

  checkTenantEmail(name: string) {
    let urlSort = this.URL + "/getByEmail";
    return this.http
      .get(urlSort + "?email=" + name)
      .pipe(
        catchError((error: HttpErrorResponse) =>
          this.errorHandler.errorHandler(error)
        )
      );
  }

  createTenant(jsonData: string) {
    return this.http
      .post(this.URL + "/create", jsonData)
      .pipe(
        catchError((error: HttpErrorResponse) =>
          this.errorHandler.errorHandler(error)
        )
      );
  }

  updateTenant(jsonData: string) {
    return this.http
      .put(this.URL + "/update", jsonData)
      .pipe(
        catchError((error: HttpErrorResponse) =>
          this.errorHandler.errorHandler(error)
        )
      );
  }

  getTimeZones() {
    return this.http
      .get(this.URL + "/getAllTimezone")
      .pipe(
        catchError((error: HttpErrorResponse) =>
          this.errorHandler.errorHandler(error)
        )
      );
  }

  getAllStacks() {
    let url = this.URL + "/stacks/getAllStacks";
    return this.http
      .get(url)
      .pipe(
        catchError((error: HttpErrorResponse) =>
          this.errorHandler.errorHandler(error)
        )
      );
  }

  getTenantNames(): Observable<Map<string, string>> {
    return this.http
      .get(this.URL + "/list/name-and-key")
      .pipe(
        catchError((error: HttpErrorResponse) =>
          this.errorHandler.errorHandler(error)
        )
      );
  }

  getCadEnabledCustomers() {
    let url = this.URL + "/cad-customers";
    return this.http
      .get(url)
      .pipe(
        catchError((error: HttpErrorResponse) =>
          this.errorHandler.errorHandler(error)
        )
      );
  }

  getCustomerStatusTypes() {
    let url = this.URL + "/tenant-status";
    return this.http
      .get(url)
      .pipe(
        catchError((error: HttpErrorResponse) =>
          this.errorHandler.errorHandler(error)
        )
      );
  }
}
