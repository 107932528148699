import { AuthConfig } from "angular-oauth2-oidc";
import { environment } from "../../environments/environment";
export const authCodeFlowConfig: AuthConfig = {
  issuer: environment.idProvider,
  redirectUri: window.location.origin + "/#/customers",
  clientId: environment.keycloakClientId,
  responseType: "code",
  scope: "openid profile email offline_access",
  showDebugInformation: true,
  disableAtHashCheck: true,
  requireHttps: false,
};
