import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";

@Injectable()
export class RoleGuard implements CanActivate {
  constructor() {}

  canActivate() {
    if (localStorage.getItem("currentUser")) {
      return true;
    }
    return false;
  }
}
