import { Pipe, PipeTransform } from "@angular/core";
import { CustomerInfo } from "../model/vendor";

@Pipe({ name: "tooltipList" })
export class TooltipCustomerInfoPipe implements PipeTransform {
  transform(vendorInfo: CustomerInfo[]): string {
    let list: string = "";
    vendorInfo.forEach((value) => {
      list += value.customerKey + "\n";
    });

    return list;
  }
}
