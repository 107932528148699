import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  AsyncValidatorFn,
  AbstractControl,
  ValidationErrors,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { NgxSpinnerService } from "ngx-spinner";
import { Customer, CustomerStatus } from "../../../model/customer";
import { TenantService } from "../../../services/tenant.service";
import { Stack } from "../../../model/stack";
import { FleetManager } from "../../../model/manager";
import { merge, Subject, forkJoin, Observable, of } from "rxjs";
import { delay, map, switchMap } from "rxjs/operators";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import {
  EditCreateType,
  EditCreate,
  Applications,
  Confirmation,
  RestrictedCustomerProperties,
  RestrictedCustomerPropertiesType,
  CustomerValidatorProperties,
  Integrations,
} from "../../../constants/enum";
import { ManagerService } from "../../../services/manager.service";
import {
  APPLICATIONS_LIST_NO_VI,
  DEBOUNCE_TIME_SEARCH_FIELDS,
  INTEGRATIONS_LIST,
} from "../../../constants/common";
import { DataSharingService } from "../../../services/data-sharing-service";
import { Router } from "@angular/router";
import { WarningDialogComponent } from "../../common-dialogs/warning-dialog/warning-dialog.component";
import { CountdownDialogComponent } from "../../common-dialogs/countdown-dialog/countdown-dialog.component";
import cloneDeep from "lodash-es/cloneDeep";
import isEqual from "lodash-es/isEqual";

@UntilDestroy()
@Component({
  templateUrl: "./add-edit-customer.component.html",
  styleUrls: ["./add-edit-customer.component.scss"],
})
export class AddEditCustomerComponent implements OnInit {
  constructor(
    private tenantservice: TenantService,
    private managerService: ManagerService,
    private spinner: NgxSpinnerService,
    public matDialog: MatDialog,
    private dataSharingService: DataSharingService,
    private router: Router
  ) {}

  readonly ENABLED_BILLABLE: string = "Enabled - Billable";

  stacks: Stack[];
  selectedStack: String;

  sharedCustomer: Customer;

  pageType: string;

  timeZones: string[];
  defaultTimeZones: string[];

  customerStatusOptions: CustomerStatus[] = [];

  applicationOptions = APPLICATIONS_LIST_NO_VI;
  selectedApplicationOptions: string[] = [];

  integrationOptions = INTEGRATIONS_LIST;
  selectedIntegrationsOptions: string[] = [];

  defaultName: string = "";
  defaultKey: string = "";
  defaultEmail: string = "";

  urlEnabled: boolean = false;

  loading: boolean = true;
  formPrepareError: boolean = false;

  editCreate: EditCreateType = EditCreate;
  restrictedCustomerProperties: RestrictedCustomerPropertiesType =
    RestrictedCustomerProperties;

  startingForm: FormGroup;

  customerForm = new FormGroup({
    id: new FormControl(),
    name: new FormControl("", {
      validators: [Validators.required],
      asyncValidators: [this.uniqueValidator(CustomerValidatorProperties.name)],
    }),
    phone: new FormControl(null),
    email: new FormControl("", {
      validators: [Validators.required, Validators.email],
      asyncValidators: [
        this.uniqueValidator(CustomerValidatorProperties.email),
      ],
    }),
    address: new FormControl(""),
    timezone: new FormControl("", [Validators.required]),
    tenantGroupName: new FormControl(""),
    profileType: new FormControl(),
    status: new FormControl(),
    tenantKey: new FormControl("", {
      validators: [Validators.required],
      asyncValidators: [this.uniqueValidator(CustomerValidatorProperties.key)],
    }),
    vehicleIntelligenceUrl: new FormControl("", [Validators.required]),
    vehicleIntelligenceAccess: new FormControl(true),
    assetIntelligenceAccess: new FormControl(false),
    fleetIntelligenceAccess: new FormControl(false),
    safetyIntelligenceAccess: new FormControl(false),
    integrationCadAccess: new FormControl(false),
    stack: new FormControl(null, [Validators.required]),
    firstName: new FormControl("", [Validators.required]),
    lastName: new FormControl("", [Validators.required]),
  });

  timeZoneSearch: FormControl = new FormControl();

  // Read Only
  keyReadOnly: boolean = true;
  urlReadOnly: boolean = true;

  fleetManagers: FleetManager[];
  customerOwnerNotFound: boolean = false;
  noFleetManagersFound: boolean = false;

  subjReload = new Subject<void>();

  // Input field focus
  isNameFocused: boolean = false;
  isTenantKeyFocused: boolean = false;
  isEmailFocused: boolean = false;
  isTimeZoneFocused: boolean = false;
  isFirstNameFocused: boolean = false;
  isLastNameFocused: boolean = false;
  isStackFocused: boolean = false;
  isVehicleIntelligenceUrlFocused: boolean = false;

  ngOnInit() {
    this.sharedCustomer = this.dataSharingService.getSharedData();
    if (this.router.url.includes(EditCreate.edit)) {
      this.pageType = EditCreate.edit;
      if (this.sharedCustomer == null) {
        // On refresh the shared data is lost. Go back to Customers table
        this.router.navigate(["/customers"]);
        return;
      }

      // Populating form with existing values
      this.customerForm.patchValue({
        assetIntelligenceAccess: this.sharedCustomer.assetIntelligenceAccess,
        email: this.sharedCustomer.email,
        firstName: this.sharedCustomer.firstName,
        fleetIntelligenceAccess: this.sharedCustomer.fleetIntelligenceAccess,
        id: this.sharedCustomer.id,
        integrationCadAccess: this.sharedCustomer.integrationCadAccess,
        lastName: this.sharedCustomer.lastName,
        name: this.sharedCustomer.name,
        safetyIntelligenceAccess: this.sharedCustomer.safetyIntelligenceAccess,
        stack: this.sharedCustomer.stack,
        status: this.sharedCustomer.status,
        tenantGroupName: this.sharedCustomer.name,
        tenantKey: this.sharedCustomer.tenantKey,
        timezone: this.sharedCustomer.timezone,
        vehicleIntelligenceUrl: this.sharedCustomer.vehicleIntelligenceUrl,
      });

      // Preparing values for validation checks later
      this.defaultName = this.sharedCustomer.name;
      this.defaultKey = this.sharedCustomer.tenantKey;
      this.defaultEmail = this.sharedCustomer.email;

      // Preparing values for dropdowns
      this.selectedStack = this.sharedCustomer.stack.name;
      this.sharedCustomer.assetIntelligenceAccess &&
        this.selectedApplicationOptions.push(Applications.assetIntelligence);
      this.sharedCustomer.fleetIntelligenceAccess &&
        this.selectedApplicationOptions.push(Applications.fleetIntelligence);
      this.sharedCustomer.safetyIntelligenceAccess &&
        this.selectedApplicationOptions.push(Applications.safetyIntelligence);
      this.sharedCustomer.integrationCadAccess &&
        this.selectedApplicationOptions.push(Integrations.cad);
    } else {
      this.pageType = EditCreate.create;
      // Disabling the wait check. For edit only.
      this.urlReadOnly = false;
      this.keyReadOnly = false;
    }
    this.prepareForm();

    // Taking a snapshot of the starting form for later
    this.startingForm = cloneDeep(this.customerForm);
  }

  onSubmit() {
    if (this.customerForm.valid) {
      this.customerForm
        .get("tenantGroupName")
        .setValue(this.customerForm.get("name").value);

      this.spinner.show();

      if (this.pageType === EditCreate.create) {
        this.tenantservice
          .createTenant(JSON.stringify(this.customerForm.value))
          .subscribe(
            (data) => {
              this.spinner.hide();
              this.router.navigate(["/customers"]);
            },
            (error) => {
              this.spinner.hide();
            }
          );
      } else {
        this.tenantservice
          .updateTenant(JSON.stringify(this.customerForm.value))
          .subscribe(
            (data) => {
              this.spinner.hide();
              this.router.navigate(["/customers"]);
            },
            (error) => {
              this.spinner.hide();
            }
          );
      }
    } else this.customerForm.markAllAsTouched();
  }

  uniqueValidator(propertyName: string): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      // If shared customer exists - it's an edit
      if (this.sharedCustomer) {
        switch (propertyName) {
          case CustomerValidatorProperties.name: {
            // If the starting edit value equals current value - no error needed
            if (control.value === this.sharedCustomer.name) {
              return of(null);
            }
            break;
          }
          case CustomerValidatorProperties.key: {
            // If the starting edit value equals current value - no error needed
            if (control.value === this.sharedCustomer.tenantKey) {
              return of(null);
            }
            break;
          }
          case CustomerValidatorProperties.email: {
            // If the starting edit value equals current value - no error needed
            if (control.value === this.sharedCustomer.email) {
              return of(null);
            }
            break;
          }
          default: {
            // If the uniqueness statement cannot be verified - error
            console.error("Case not found for unique switch statement");
            return of({ isNotUnique: true });
          }
        }
      }
      return of(control.value).pipe(
        delay(DEBOUNCE_TIME_SEARCH_FIELDS),
        switchMap((value) => {
          if (!value) {
            // No validation error if the value is empty
            return of(null);
          }
          switch (propertyName) {
            case CustomerValidatorProperties.name: {
              return this.tenantservice.checkCustomerName(value).pipe(
                map((data) => {
                  let isResultUnique = !(
                    data &&
                    data.name.toLowerCase() != this.defaultName.toLowerCase()
                  );
                  return isResultUnique ? null : { isNotUnique: true };
                })
              );
            }
            case CustomerValidatorProperties.key: {
              return this.tenantservice.checkTenantKey(value).pipe(
                map((data) => {
                  let isResultUnique = !(
                    data &&
                    data.name.toLowerCase() != this.defaultName.toLowerCase()
                  );
                  return isResultUnique ? null : { isNotUnique: true };
                })
              );
            }
            case CustomerValidatorProperties.email: {
              return this.tenantservice.checkTenantEmail(value).pipe(
                map((data) => {
                  let isResultUnique = !(
                    data &&
                    data.name.toLowerCase() != this.defaultName.toLowerCase()
                  );
                  return isResultUnique ? null : { isNotUnique: true };
                })
              );
            }
          }
        })
      );
    };
  }

  prepareForm() {
    merge(this.subjReload)
      .pipe(
        untilDestroyed(this),
        switchMap((): any => {
          if (this.pageType === EditCreate.edit) {
            return forkJoin({
              fleetManagers: this.managerService.getFleetManagersForCustomer(
                this.sharedCustomer.tenantKey
              ),
              stacks: this.tenantservice.getAllStacks(),
              timezones: this.tenantservice.getTimeZones(),
              statuses: this.tenantservice.getCustomerStatusTypes(),
            });
          } else {
            return forkJoin({
              stacks: this.tenantservice.getAllStacks(),
              timezones: this.tenantservice.getTimeZones(),
              statuses: this.tenantservice.getCustomerStatusTypes(),
            });
          }
        })
      )
      .subscribe({
        next: ({
          fleetManagers: fleetManagers,
          stacks: stacks,
          timezones: timezones,
          statuses: statuses,
        }) => {
          if (this.pageType === EditCreate.edit) {
            if (fleetManagers) {
              this.fleetManagers = fleetManagers["entity"];
              this.validateCustomerOwner(
                this.fleetManagers,
                this.sharedCustomer.email
              );
            } else {
              this.noFleetManagersFound = true;
            }
          }
          if (stacks) {
            this.stacks = stacks["entity"];
          }
          if (timezones) {
            this.timeZones = timezones["entity"];
            this.defaultTimeZones = timezones["entity"];
          }
          if (statuses) {
            this.customerStatusOptions = statuses["entity"];
            // Verify the value exists before setting it
            if (this.customerStatusOptions[1].name !== this.ENABLED_BILLABLE) {
              this.formPrepareError = true;
              console.error("Default status value was not found");
            }
            // If create, set default as 'Enabled - Billable'
            if (this.pageType === EditCreate.create) {
              this.customerForm.get("status").setValue(1);
            }
          }
          this.loading = false;
        },
        error: () => {
          this.loading = false;
          this.formPrepareError = true;
        },
      });
    this.subjReload.next();
  }

  validateCustomerOwner(fleetManagers: FleetManager[], customerOwner: string) {
    const found = fleetManagers.find(
      (element) => element.email === customerOwner
    );
    if (!found) {
      this.customerOwnerNotFound = true;
      this.customerForm.get("email").setValue(null);
    }
  }

  getErrorMessage(name: string) {
    switch (name) {
      case "name": {
        if (
          this.customerForm.controls["name"].hasError("required") &&
          this.customerForm.controls["name"].touched
        ) {
          return "You must enter a customer name.";
        } else if (this.customerForm.controls["name"].hasError("isNotUnique")) {
          return "A customer already exists with that name.";
        }
        return "Error";
      }
      case "tenantKey": {
        if (
          this.customerForm.controls["tenantKey"].hasError("required") &&
          this.customerForm.controls["tenantKey"].touched
        ) {
          return "You must enter a customer key.";
        } else if (
          this.customerForm.controls["tenantKey"].hasError("isNotUnique")
        ) {
          return "A customer already exists with that key.";
        }
        return "Error";
      }
      case "email": {
        if (this.noFleetManagersFound) {
          return "No Fleet Managers found.";
        } else if (
          !this.customerForm.controls["email"].touched &&
          this.customerOwnerNotFound
        ) {
          return "Existing Customer Owner is not a Fleet Manager.";
        } else if (
          this.customerForm.controls["email"].hasError("required") &&
          this.customerForm.controls["email"].touched
        ) {
          return "You must enter a customer email.";
        } else if (
          this.customerForm.controls["email"].hasError("email") &&
          this.customerForm.controls["email"].touched
        ) {
          return "You must enter a valid email.";
        } else if (
          this.customerForm.controls["email"].hasError("isNotUnique")
        ) {
          return "A customer already exists with that email.";
        }
        return "Error";
      }
      case "timezone": {
        if (this.customerForm.controls["timezone"].touched) {
          return "You must choose a timezone.";
        }
        return "Error";
      }
      case "vehicleIntelligenceUrl": {
        if (this.customerForm.controls["vehicleIntelligenceUrl"].touched) {
          return "You must enter a URL.";
        }
        return "Error";
      }
      case "stack": {
        if (this.customerForm.controls["stack"].touched) {
          return "You must choose a stack.";
        }
        return "Error";
      }
      case "firstName": {
        if (this.customerForm.controls["firstName"].touched) {
          return "You must enter a name.";
        }
        return "Error";
      }
      case "lastName": {
        if (this.customerForm.controls["lastName"].touched) {
          return "You must enter a name.";
        }
        return "Error";
      }
    }
    return null;
  }

  filterTimeZones(word: string) {
    this.timeZones = this.defaultTimeZones.filter((element) => {
      return element.toLowerCase().includes(word.toLowerCase());
    });
  }

  openedChange(opened: boolean) {
    if (!opened) {
      this.timeZones = this.defaultTimeZones;
    }
  }

  noSpace(event: KeyboardEvent) {
    if (event.code === "Space") {
      event.preventDefault();
    }
  }

  selectNewCustomerOwner(fleetManager: FleetManager) {
    this.customerForm.get("firstName").setValue(fleetManager.firstName);
    this.customerForm.get("lastName").setValue(fleetManager.lastName);
  }

  areYouSureKey(type: RestrictedCustomerProperties, status: boolean) {
    if (status) {
      // Only on edit customer
      if (this.pageType == EditCreate.edit) {
        let dialogRef = this.matDialog.open(CountdownDialogComponent, {
          data: { type: type },
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (result == Confirmation.confirm) {
            switch (type) {
              case RestrictedCustomerProperties.stack: {
                document
                  .getElementById("stack_click_interceptor")
                  .classList.add("removed");
                break;
              }
              case RestrictedCustomerProperties.applications: {
                document
                  .getElementById("application_click_interceptor")
                  .classList.add("removed");
                break;
              }
              case RestrictedCustomerProperties.enabled: {
                document
                  .getElementById("enabled_click_interceptor")
                  .classList.add("removed");
                break;
              }
              case RestrictedCustomerProperties.url: {
                this.urlReadOnly = false;
                break;
              }
              default:
                break;
            }
          }
        });
      }
    }
  }

  // Used to prevent errors appearing too soon
  onInputFocus(formValue: string) {
    switch (formValue) {
      case "name":
        this.isNameFocused = true;
        return;
      case "tenantKey":
        this.isTenantKeyFocused = true;
        return;
      case "email":
        this.isEmailFocused = true;
        return;
      case "timezone":
        this.isTimeZoneFocused = true;
        return;
      case "firstName":
        this.isFirstNameFocused = true;
        return;
      case "lastName":
        this.isLastNameFocused = true;
        return;
      case "stack":
        this.isStackFocused = true;
        return;
      case "vehicleIntelligenceUrl":
        this.isVehicleIntelligenceUrlFocused = true;
        return;
      default:
        console.error("Case not found for onInputFocus()");
        return;
    }
  }

  onInputBlur(formValue: string) {
    switch (formValue) {
      case "name":
        this.isNameFocused = false;
        return;
      case "tenantKey":
        this.isTenantKeyFocused = false;
        return;
      case "email":
        this.isEmailFocused = false;
        return;
      case "timezone":
        this.isTimeZoneFocused = false;
        return;
      case "firstName":
        this.isFirstNameFocused = false;
        return;
      case "lastName":
        this.isLastNameFocused = false;
        return;
      case "stack":
        this.isStackFocused = false;
        return;
      case "vehicleIntelligenceUrl":
        this.isVehicleIntelligenceUrlFocused = false;
        return;
      default:
        console.error("Case not found for onInputBlur()");
        return;
    }
  }

  openWarningDialog(integrationForm: FormGroup) {
    // If the form was not edited - No need for warning
    if (isEqual(this.startingForm.value, integrationForm.value)) {
      this.router.navigate(["/customers"]);
      return;
    }
    // Else pop a 'Discard Changes?' warning
    let dialogRef = this.matDialog.open(WarningDialogComponent, {
      data: {
        title: "Are you sure!",
        message: "Please confirm you wish to discard current changes made.",
        buttonConfirm: "Yes, Cancel",
        buttonCancel: "Keep Editing",
      },
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.router.navigate(["/customers"]);
      }
    });
  }

  onApplicationOptionSelected(applications: string[]) {
    const accessControls: { [key: string]: string } = {
      assetIntelligenceAccess: Applications.assetIntelligence,
      fleetIntelligenceAccess: Applications.fleetIntelligence,
      safetyIntelligenceAccess: Applications.safetyIntelligence,
      integrationCadAccess: Integrations.cad,
    };

    Object.entries(accessControls).forEach(([controlName, application]) => {
      this.customerForm
        .get(controlName)
        .setValue(applications.includes(application));
    });
  }

  onStackChange(stackName: string) {
    this.customerForm
      .get("stack")
      .setValue(this.stacks.find((el) => el.name === stackName));
  }
}
