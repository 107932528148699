import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { ErrorHandlerService } from "./response-utils.service";
import { catchError } from "rxjs/operators";
import { of } from "rxjs";
import { CadIntegration } from "../model/vendor";

@Injectable()
export class VendorService {
  private readonly cadVendorController: string =
    environment.apiUrl + "/cad/vendor";

  private readonly cadAuthController: string =
    environment.apiUrl + "/cad/auth-type";

  constructor(
    private http: HttpClient,
    private errorHandler: ErrorHandlerService
  ) {}

  saveVendor(integration: CadIntegration) {
    return this.http
      .post(this.cadVendorController, integration)
      .pipe(
        catchError((error: HttpErrorResponse) =>
          this.errorHandler.errorHandler(error)
        )
      );
  }

  updateVendor(integration: CadIntegration) {
    let url = this.cadVendorController + "/" + integration.id;
    return this.http
      .put(url, integration)
      .pipe(
        catchError((error: HttpErrorResponse) =>
          this.errorHandler.errorHandler(error)
        )
      );
  }

  getVendorList() {
    return this.http
      .get(this.cadVendorController)
      .pipe(
        catchError((error: HttpErrorResponse) =>
          this.errorHandler.errorHandler(error)
        )
      );
  }

  isValueUnique(type: string, value: string) {
    let url = this.cadVendorController + "/unique/" + type + "/" + value;
    return this.http
      .get(url)
      .pipe(
        catchError((error: HttpErrorResponse) =>
          this.errorHandler.errorHandler(error)
        )
      );
  }

  getAuthTypes() {
    return this.http
      .get(this.cadAuthController)
      .pipe(
        catchError((error: HttpErrorResponse) =>
          this.errorHandler.errorHandler(error)
        )
      );
  }

  getFrequencies() {
    // let url = "http://localhost:3000/vendor/frequencies";
    // return this.http.get(url).pipe(catchError(_errorHandler));
    // Hardcoding until the end point is set up
    return of([15, 30, 45, 60]);
  }

  getEventTypes() {
    // let url = "http://localhost:3000/vendor/event-types";
    // return this.http.get(url).pipe(catchError(_errorHandler));
    // Hardcoding until the end point is set up
    return of(["Vehicle Safety", "Vehicle Status", "Vehicle Location +"]);
  }
}
