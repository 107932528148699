import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
@Component({
  templateUrl: "./warning-dialog.component.html",
  styleUrls: ["./warning-dialog.component.scss"],
})
export class WarningDialogComponent {
  constructor(
    public dialog: MatDialogRef<WarningDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    data: {
      title: string;
      message: string;
      buttonConfirm: string;
      buttonCancel: string;
    }
  ) {
    this.title = data.title;
    this.message = data.message;
    this.buttonConfirm = data.buttonConfirm;
    this.buttonCancel = data.buttonCancel;
  }

  title: string;
  message: string;
  buttonConfirm: string;
  buttonCancel: string;
}
