import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { Navigation } from "./protected/navigation/navigation.component";
import { AuthGuard } from "./guard/auth.guard";
import { ManagerComponent } from "./protected/manager/manager.component";
import { CustomerComponent } from "./protected/customer/customer.component";
import { CadIntegrationsComponent } from "./protected/cad-integrations/cad-integrations.component";
import { AddEditCadIntegrationComponent } from "./protected/cad-integrations/add-edit-cad-integration/add-edit-cad-integration.component";
import { IntegrationsGuard } from "./guard/route.guard";
import { AddEditCustomerComponent } from "./protected/customer/add-edit-customer/add-edit-customer.component";

const routes: Routes = [
  { path: "", redirectTo: "customers", pathMatch: "full" },
  {
    path: "",
    data: {
      breadcrumb: "/",
    },
    component: Navigation,
    canActivate: [AuthGuard],
    children: [
      {
        path: "customers",
        component: CustomerComponent,
        canActivate: [AuthGuard],
        data: {
          roles: ["ROLE_SYSTEM_ADMIN"],
          breadcrumb: "customers",
        },
      },
      {
        path: "customers/:type",
        component: AddEditCustomerComponent,
        canActivate: [AuthGuard],
        data: {
          roles: ["ROLE_SYSTEM_ADMIN"],
          breadcrumb: "customers",
        },
      },
      {
        path: "integrations",
        component: CadIntegrationsComponent,
        canActivate: [AuthGuard],
        data: {
          roles: ["ROLE_SYSTEM_ADMIN"],
          breadcrumb: "integrations",
        },
      },
      {
        path: "integrations/:type",
        component: AddEditCadIntegrationComponent,
        canActivate: [AuthGuard, IntegrationsGuard],
        data: {
          roles: ["ROLE_SYSTEM_ADMIN"],
          breadcrumb: "integrations",
          allowedTypes: ["add", "edit"],
        },
      },
      {
        path: "managers",
        component: ManagerComponent,
        canActivate: [AuthGuard],
        data: {
          roles: ["ROLE_SYSTEM_ADMIN"],
          breadcrumb: "managers",
        },
      },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
