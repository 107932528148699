export enum Active {
  active = "Enabled",
  inactive = "Disabled",
}

export enum Applications {
  vehicleIntelligence = "Vehicle Intelligence",
  fleetIntelligence = "Fleet Intelligence",
  assetIntelligence = "Asset Intelligence",
  safetyIntelligence = "Safety Intelligence",
}

export enum Integrations {
  cad = "CAD",
}

export enum Confirmation {
  confirm = "Confirm",
  cancel = "Cancel",
}

export enum EditCreate {
  edit = "edit",
  create = "create",
  add = "add",
}

export type EditCreateType = typeof EditCreate;

export enum RestrictedCustomerProperties {
  key = "Customer Key",
  enabled = "Enabled",
  stack = "Stack",
  applications = "Applications",
  url = "URL",
}

export type RestrictedCustomerPropertiesType =
  typeof RestrictedCustomerProperties;

export enum CustomerValidatorProperties {
  name = "name",
  key = "key",
  url = "url",
  email = "email",
}

// Using - as they will be included in a URL
export enum IntegrationProperties {
  vendorName = "vendor-name",
  vendorKey = "vendor-key",
}
