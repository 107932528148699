import { Injectable } from "@angular/core";
import { filter } from "rxjs/operators";
import {
  OAuthService,
  AuthConfig,
  NullValidationHandler,
} from "angular-oauth2-oidc";

@Injectable()
export class AuthService {
  private _decodedAccessToken: any;
  private _decodedIDToken: any;

  get decodedAccessToken() {
    return this._decodedAccessToken;
  }

  get decodedIDToken() {
    return this._decodedIDToken;
  }

  constructor(
    private readonly oauthService: OAuthService,
    private readonly authConfig: AuthConfig
  ) {}

  async initAuth(): Promise<any> {
    // check if tokens expired (prevent bug with cyclic redirect)
    // TODO: investigate if this issue can be solved with oauth package (config/events handling)
    const now = Date.now();

    const expired = [
      localStorage.getItem("expires_at"),
      localStorage.getItem("id_token_expires_at"),
    ].reduce((acc, item) => (item && now > +item ? true : acc), false);

    if (expired) {
      // tokens are expired, so remove KC data from localstorage
      [
        "refresh_token",
        "PKCE_verifier",
        "nonce",
        "id_token",
        "access_token",
        "id_token_claims_obj",
        "session_state",
        "access_token_stored_at",
        "expires_at",
        "id_token_expires_at",
        "id_token_stored_at",
        "granted_scopes",
        "currentUser",
      ].forEach((key) => {
        localStorage.removeItem(key);
      });
    }

    return new Promise((resolveFn, rejectFn) => {
      this.oauthService.configure(this.authConfig);
      this.oauthService.setStorage(localStorage);
      this.oauthService.tokenValidationHandler = new NullValidationHandler();

      this.oauthService.events
        .pipe(
          filter((e: any) => {
            return e.type === "token_received";
          })
        )
        .subscribe(() => this.handleNewToken());

      this.oauthService.loadDiscoveryDocumentAndLogin().then((isLoggedIn) => {
        if (isLoggedIn) {
          this.oauthService.setupAutomaticSilentRefresh();
          resolveFn(null);
        } else {
          this.oauthService.initImplicitFlow();
          rejectFn();
        }
      });
    });
  }

  logOut(): void {
    this.oauthService.logOut();
  }

  handleNewToken() {
    const rolespath = [];
    const accessToken = JSON.parse(
      atob(localStorage.getItem("access_token").split(".")[1])
    );
    if (accessToken.user_type == "System_Admin") {
      const pree = "ROLE_" + accessToken.user_type.toUpperCase();
      rolespath.push(pree);
      sessionStorage.setItem("currentUserRole", JSON.stringify(rolespath));
      localStorage.setItem("currentUser", localStorage.getItem("access_token"));
    } else {
      this.logOut();
    }
  }
}
