import { Injectable, inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class IntegrationsGuard {
  constructor(private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot): boolean {
    // Define your allowed types here
    const allowedTypes = ["add", "edit"];
    const type = next.params.type;

    if (allowedTypes.includes(type)) {
      return true;
    }
    // Redirect to a default route
    this.router.navigate(["/integrations"]);
    return false;
  }
}

export const AuthGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot
): boolean => {
  return inject(IntegrationsGuard).canActivate(next);
};
