import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
@Component({
  templateUrl: "./success-dialog.component.html",
  styleUrls: ["./success-dialog.component.scss"],
})
export class SuccessDialogComponent {
  constructor(
    public dialog: MatDialogRef<SuccessDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    data: { title: string; message: string; button: string }
  ) {
    this.title = data.title;
    this.message = data.message;
    this.button = data.button;
  }

  title: string;
  message: string;
  button: string;
}
