import { Active, Applications, Integrations } from "./enum";

export const DEBOUNCE_TIME_SELECTS = 700;
export const DEBOUNCE_TIME_SEARCH_FIELDS = 600;

export const ACTIVE_OPTIONS_LIST = [Active.active, Active.inactive];

export const APPLICATIONS_LIST_NO_VI = [
  Applications.assetIntelligence,
  Applications.fleetIntelligence,
  Applications.safetyIntelligence,
];

export const INTEGRATIONS_LIST = [Integrations.cad];
