import { Component, OnInit } from "@angular/core";
import { DataSharingService } from "../../../services/data-sharing-service";
import { CustomerInfo, CadIntegration, AuthenticationType } from "../../../model/vendor";
import { Active, EditCreate, EditCreateType, IntegrationProperties } from "../../../constants/enum";
import { Router } from "@angular/router";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
  AsyncValidatorFn,
} from "@angular/forms";
import { merge, Subject, forkJoin, of, Observable } from "rxjs";
import { VendorService } from "../../../services/vendor.service";
import { DEBOUNCE_TIME_SEARCH_FIELDS } from "../../../constants/common";
import { delay, map, switchMap } from "rxjs/operators";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { TenantService } from "../../../services/tenant.service";
import { MatDialog } from "@angular/material/dialog";
import { SuccessDialogComponent } from "../../common-dialogs/success-dialog/success-dialog.component";
import { WarningDialogComponent } from "../../common-dialogs/warning-dialog/warning-dialog.component";
import { NgxSpinnerService } from "ngx-spinner";
import cloneDeep from "lodash-es/cloneDeep";
import isEqual from "lodash-es/isEqual";

@UntilDestroy()
@Component({
  selector: "app-add-edit-cad-integration",
  templateUrl: "./add-edit-cad-integration.component.html",
  styleUrls: ["./add-edit-cad-integration.component.scss"],
})
export class AddEditCadIntegrationComponent implements OnInit {
  constructor(
    private dataSharingService: DataSharingService,
    private router: Router,
    private vendorService: VendorService,
    private tenantService: TenantService,
    private matDialog: MatDialog,
    private spinner: NgxSpinnerService
  ) {}
  sharedIntegration: CadIntegration;
  pageType: string;
  pageNameTitle: string = "";

  editCreate: EditCreateType = EditCreate;

  loading: boolean = true;
  formPrepareError: boolean = false;

  startingForm: FormGroup;

  // Form
  integrationForm = new FormGroup({
    apiKey: new FormControl(""),
    authType: new FormControl(null as AuthenticationType, [Validators.required]),
    authEndpointUrl: new FormControl(""),
    authPassword: new FormControl(""),
    authUsername: new FormControl(""),
    contactEmail: new FormControl("", [Validators.email]),
    contactName: new FormControl(""),
    contactPhone: new FormControl(""),
    customers: new FormControl([] as string[], [Validators.required]),
    enabled: new FormControl(Active.active, [Validators.required]),
    // eventTypes: new FormControl([this.defaultEventType], [Validators.required]),
    // frequency: new FormControl(null, [Validators.required]),
    id: new FormControl(null as number),
    pushUrl: new FormControl("", [Validators.required]),
    vendorKey: new FormControl("", [Validators.required], this.uniqueValidator(IntegrationProperties.vendorKey)),
    vendorName: new FormControl("", [Validators.required], this.uniqueValidator(IntegrationProperties.vendorName)),
  });
  subjReload = new Subject<void>();

  // Form Dropdown Values
  customers: CustomerInfo[] = [];
  authTypes: AuthenticationType[] = [];
  // frequencies: number[] = [];
  // eventTypes: string[] = [];
  enabledList: string[] = Object.values(Active);

  // Input field focus
  isVendorNameFocused: boolean = false;
  isVendorKeyFocused: boolean = false;
  isPushUrlFocused: boolean = false;

  ngOnInit() {
    this.sharedIntegration = this.dataSharingService.getSharedData();
    if (this.router.url.includes(EditCreate.edit)) {
      this.pageType = EditCreate.edit;
      if (this.sharedIntegration == null) {
        // On refresh the shared data is lost. Go back to Integrations table
        this.router.navigate(["/integrations"]);
        return;
      }
      this.pageNameTitle = this.sharedIntegration.vendorName;

      // Todo: These values are commented out until the proper ones will be added in future
      this.integrationForm.patchValue({
        apiKey: this.sharedIntegration.apiKey,
        authType: this.sharedIntegration.authType,
        authEndpointUrl: this.sharedIntegration.authEndpointUrl,
        authPassword: this.sharedIntegration.authPassword,
        authUsername: this.sharedIntegration.authUsername,
        contactEmail: this.sharedIntegration.contactInformation.contactEmail,
        contactName: this.sharedIntegration.contactInformation.contactName,
        contactPhone: this.sharedIntegration.contactInformation.contactPhone,
        customers: this.sharedIntegration.customers.map((customer) => customer.customerKey),
        enabled: this.sharedIntegration.enabled ? Active.active : Active.inactive,
        // eventTypes: sharedIntegration.eventTypes,
        // frequency: sharedIntegration.frequency,
        id: this.sharedIntegration.id,
        pushUrl: this.sharedIntegration.pushUrl,
        vendorKey: this.sharedIntegration.vendorKey,
        vendorName: this.sharedIntegration.vendorName,
      });
    } else {
      this.pageType = EditCreate.add;
    }
    // Taking a snapshot of the starting form for later
    this.startingForm = cloneDeep(this.integrationForm);

    this.prepareForm();
  }

  onSubmit(form: FormGroup) {
    if (form.valid) {
      let integration = {
        // Setting null values until we get offical values from DB
        apiKey: form.value.apiKey.toLowerCase(),
        authEndpointUrl: form.value.authEndpointUrl,
        authPassword: form.value.authPassword,
        authUsername: form.value.authUsername,
        authType: form.value.authType,
        contactInformation: {
          contactEmail: form.value.contactEmail,
          contactName: form.value.contactName,
          contactPhone: form.value.contactPhone,
        },
        customers: this.generateCustomerInfo(form.value.customers),
        enabled: form.value.enabled == Active.active,
        // eventTypes: form.value.eventTypes,
        eventTypes: null,
        // frequency: form.value.frequency,
        frequency: null,
        id: form.value.id,
        pushUrl: form.value.pushUrl,
        vendorName: form.value.vendorName,
        vendorKey: form.value.vendorKey,
      } as CadIntegration;
      this.openAreYouSureDialog(integration.vendorName, integration.customers).subscribe((data) => {
        if (data) {
          this.spinner.show();
          if (this.pageType == EditCreate.add) {
            this.vendorService.saveVendor(integration).subscribe(
              (data) => {
                this.spinner.hide();
                this.openSuccessDialog(this.pageType);
              },
              (error) => {
                this.spinner.hide();
              }
            );
          } else {
            this.vendorService.updateVendor(integration).subscribe(
              (data) => {
                this.spinner.hide();
                this.openSuccessDialog(this.pageType);
              },
              (error) => {
                this.spinner.hide();
              }
            );
          }
        }
      });
    } else {
      form.markAllAsTouched();
    }
  }

  uniqueValidator(propertyName: string): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      // If shared integration exists - it's an edit
      if (this.sharedIntegration) {
        switch (propertyName) {
          case IntegrationProperties.vendorName: {
            // If the starting edit value equals current value - no error needed
            if (control.value === this.sharedIntegration.vendorName) {
              return of(null);
            }
            break;
          }
          case IntegrationProperties.vendorKey: {
            // If the starting edit value equals current value - no error needed
            if (control.value === this.sharedIntegration.vendorKey) {
              return of(null);
            }
            break;
          }
          default: {
            // If the uniqueness statement cannot be verified - error
            console.error("Case not found for unique switch statement");
            return of({ isNotUnique: true });
          }
        }
      }
      return of(control.value).pipe(
        delay(DEBOUNCE_TIME_SEARCH_FIELDS),
        switchMap((value) => {
          if (!value) {
            // No validation error if the value is empty
            return of(null);
          }
          return this.vendorService.isValueUnique(propertyName, value).pipe(
            map((isResultUnique) => {
              return isResultUnique ? null : { isNotUnique: true };
            })
          );
        })
      );
    };
  }

  prepareForm() {
    merge(this.subjReload)
      .pipe(
        untilDestroyed(this),
        switchMap((): any => {
          return forkJoin({
            customers: this.tenantService.getCadEnabledCustomers(),
            authTypes: this.vendorService.getAuthTypes(),
            // frequencies: this.vendorService.getFrequencies(),
            // eventTypes: this.vendorService.getEventTypes(),
          });
        })
      )
      .subscribe({
        next: ({
          customers: customers,
          authTypes: authTypes,
          // frequencies: data3,
          // eventTypes: data4,
        }) => {
          if (customers) {
            this.customers = customers;
          }
          if (authTypes) {
            this.authTypes = authTypes;
            this.formPrepareError = this.authTypes.length === 0;

            if (this.formPrepareError) {
              console.error("Default status value was not found");
              return;
            }

            if (this.pageType === this.editCreate.add) {
              this.integrationForm.get("authType").setValue(this.authTypes[0]);
              this.startingForm.get("authType").setValue(this.authTypes[0]);
            } else {
              this.integrationForm
                .get("authType")
                .setValue(
                  this.authTypes.find((authType) => authType.authMethod === this.sharedIntegration.authType.authMethod)
                );
            }

            this.changeValidators(this.integrationForm.get("authType").value);
          }
          // if (data3) {
          //   this.frequencies = data3;
          // }
          // if (data4) {
          //   this.eventTypes = data4;
          // }

          this.loading = false;
        },
        error: () => {
          this.loading = false;
          this.formPrepareError = true;
        },
      });
    this.subjReload.next();
  }

  generateCustomerInfo(customers: string[]) {
    let customerInfo: CustomerInfo[] = [];

    customers.forEach((customer) => {
      this.customers.forEach((info) => {
        if (info.customerKey === customer) {
          customerInfo.push(info);
        }
      });
    });
    return customerInfo;
  }

  getErrorMessage(field: string) {
    switch (field) {
      case "vendorName": {
        if (this.integrationForm.controls["vendorName"].hasError("isNotUnique")) {
          return "A vendor already exists with that name.";
        }
        if (
          this.integrationForm.controls["vendorName"].hasError("required") &&
          this.integrationForm.controls["vendorName"].touched
        ) {
          return "You must enter a vendor name.";
        }
        return "Error";
      }
      case "vendorKey": {
        if (this.integrationForm.controls["vendorKey"].hasError("isNotUnique")) {
          return "A vendor already exists with that key.";
        }
        if (
          this.integrationForm.controls["vendorKey"].hasError("required") &&
          this.integrationForm.controls["vendorKey"].touched
        ) {
          return "You must enter a vendor key.";
        }
        return "Error";
      }
      case "pushUrl": {
        if (
          this.integrationForm.controls["pushUrl"].hasError("required") &&
          this.integrationForm.controls["pushUrl"].touched
        ) {
          return "You must enter a valid endpoint.";
        }
        return "Error";
      }
      case "customers": {
        if (
          this.integrationForm.controls["customers"].hasError("required") &&
          this.integrationForm.controls["customers"].touched
        ) {
          return "You must select at least one customer.";
        }
        return "Error";
      }
      case "authType": {
        if (
          this.integrationForm.controls["authType"].hasError("required") &&
          this.integrationForm.controls["authType"].touched
        ) {
          return "You must select an authentication type.";
        }
        return "Error";
      }
      case "apiKey": {
        if (
          this.integrationForm.controls["apiKey"].hasError("required") &&
          this.integrationForm.controls["apiKey"].touched
        ) {
          return "You must enter an api key.";
        }
        return "Error";
      }
      case "contactEmail": {
        if (
          this.integrationForm.controls["contactEmail"].hasError("email") &&
          this.integrationForm.controls["contactEmail"].touched
        ) {
          return "You must enter a valid email.";
        }
        return "Error";
      }
      case "authEndpointUrl": {
        if (
          this.integrationForm.controls["authEndpointUrl"].hasError("required") &&
          this.integrationForm.controls["authEndpointUrl"].touched
        ) {
          return "You must enter a valid authentication endpoint.";
        }
        return "Error";
      }
      case "authUsername": {
        if (
          this.integrationForm.controls["authUsername"].hasError("required") &&
          this.integrationForm.controls["authUsername"].touched
        ) {
          return "You must enter a valid authentication username.";
        }
        return "Error";
      }
      case "authPassword": {
        if (
          this.integrationForm.controls["authPassword"].hasError("required") &&
          this.integrationForm.controls["authPassword"].touched
        ) {
          return "You must enter a valid authentication password.";
        }
        return "Error";
      }
    }
    return null;
  }

  openSuccessDialog(pageType: string) {
    let updatedType = pageType == EditCreate.add ? "created" : "updated";
    this.matDialog.open(SuccessDialogComponent, {
      data: {
        title: `Integration ${updatedType}!`,
        message: `You have successfully ${updatedType} an ACETECH™ Integration.`,
        button: "Great!",
      },
    });
    this.router.navigate(["/integrations"]);
  }

  openWarningDialog(integrationForm: FormGroup) {
    // If the form was not edited - No need for warning
    if (isEqual(this.startingForm.value, integrationForm.value)) {
      this.router.navigate(["/integrations"]);
      return;
    }
    // Else pop a discard changes warning
    let dialogRef = this.matDialog.open(WarningDialogComponent, {
      data: {
        title: "Are you sure!",
        message: "Please confirm you wish to discard current changes made.",
        buttonConfirm: "Yes, Cancel",
        buttonCancel: "Keep Editing",
      },
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.router.navigate(["/integrations"]);
      }
    });
  }

  openAreYouSureDialog(vendor: string, customersInfo: CustomerInfo[]) {
    // Have the associated customers changed?
    // Add an extra check to make sure the
    // correct customers are associated with the correct vendor
    if (isEqual(this.startingForm.get("customers").value, this.integrationForm.get("customers").value)) {
      return of(true);
    }
    const customers: string[] = customersInfo.map((customer) => customer.customerKey);
    let dialogRef = this.matDialog.open(WarningDialogComponent, {
      data: {
        title: "Are you sure!",
        message: `Please confirm you wish to associate the vendor:\n<b> ${vendor}</b>\nWith the following customers:\n<b> ${customers}</b>`,
        buttonConfirm: this.pageType == EditCreate.edit ? "Yes, Update!" : "Yes, Create!",
        buttonCancel: "Keep Editing",
      },
    });
    return dialogRef.afterClosed();
  }

  // Used to prevent errors appearing too soon
  onInputFocus(formValue: string) {
    switch (formValue) {
      case "vendorName":
        this.isVendorNameFocused = true;
        return;
      case "vendorKey":
        this.isVendorKeyFocused = true;
        return;
      case "pushUrl":
        this.isPushUrlFocused = true;
        return;
      default:
        console.error("Case not found for onInputFocus()");
        return;
    }
  }

  onInputBlur(formValue: string) {
    switch (formValue) {
      case "vendorName":
        this.isVendorNameFocused = false;
        return;
      case "vendorKey":
        this.isVendorKeyFocused = false;
        return;
      case "pushUrl":
        this.isPushUrlFocused = false;
        return;
      default:
        console.error("Case not found for onInputBlur()");
        return;
    }
  }

  changeValidators(authType: AuthenticationType) {
    // Need to set validators based on authentication type selected.
    const controls: string[] = ["authEndpointUrl", "authPassword", "authUsername", "apiKey"];

    // Clear existing validators
    controls.forEach((control) => {
      this.integrationForm.get(control).clearValidators();
      this.integrationForm.get(control).markAsUntouched();
    });

    // Set validators based on authentication type selected
    switch (authType.authMethod) {
      case this.authTypes[0].authMethod:
        this.integrationForm.get("apiKey").setValidators([Validators.required]);
        break;
      case this.authTypes[1].authMethod:
        this.integrationForm.get("authEndpointUrl").setValidators([Validators.required]);
        this.integrationForm.get("authPassword").setValidators([Validators.required]);
        this.integrationForm.get("authUsername").setValidators([Validators.required]);
        break;
      default:
        console.error("Case not found for changeValidators()");
    }
    // Update value and validity after setting new validators
    controls.forEach((control) => {
      this.integrationForm.get(control).updateValueAndValidity();
    });
  }

  ngOnDestroy() {}
}
