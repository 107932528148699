import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { Observable } from "rxjs";
import { OAuthService } from "angular-oauth2-oidc";
@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private oauth: OAuthService) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    var hasIdtoken = this.oauth.hasValidIdToken();
    var hasAccessToken = this.oauth.hasValidAccessToken();
    return hasIdtoken && hasAccessToken;
  }
}
