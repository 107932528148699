import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
@Component({
  templateUrl: "./error-dialog.component.html",
  styleUrls: ["./error-dialog.component.scss"],
})
export class ErrorDialogComponent {
  constructor(
    public dialog: MatDialogRef<ErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    data: { title: string; message: string }
  ) {
    this.title = data.title;
    this.message = data.message;
  }

  title: string;
  message: string;
}
