import { AfterViewInit, Component, HostListener, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../../guard/auth.service";
import { Breadcrumb } from "../../model/breadcrumb";

@Component({
  selector: "app-navigation",
  templateUrl: "./navigation.component.html",
  styleUrls: ["./navigation.component.scss"],
})
export class Navigation implements OnInit, AfterViewInit {
  expanded: boolean = true;
  expansionOption: boolean = true;
  smallerScreen: number = 1450;
  crumbs: Breadcrumb[] = [];
  resizeActive: boolean = false;

  constructor(public router: Router, public oauthService: AuthService) {
    router.events.subscribe(() => {
      this.generateBreadCrumbs();
    });
  }

  ngOnInit() {
    if (window.innerWidth <= this.smallerScreen) {
      this.expanded = false;
      this.expansionOption = false;
    }
  }

  ngAfterViewInit(): void {
    // Added after Initial load to prevent unwanted transition
    setTimeout(() => {
      document.getElementById("drawer").classList.add("common-drawer");
    }, 1);
  }

  logout() {
    this.oauthService.logOut();
  }

  // Does not do much as the amount of pages is limited
  generateBreadCrumbs() {
    let here = location.href.split("/").slice(3);
    this.crumbs = [];
    for (let i = 0; i < here.length; i++) {
      let part = here[i];
      if (part != "#") {
        let link = "/" + here.slice(0, i + 1).join("/");
        this.crumbs.push({ text: part, link: link });
      }
    }
  }

  resize(event: MouseEvent) {
    // Preventing spamming of nav button
    if (this.resizeActive === false) {
      this.expanded = !this.expanded;

      this.resizeActive = true;
      let count = 0;

      let interval2 = setInterval(() => {
        count++;
        if (count == 19) {
          this.resizeActive = false;
          clearInterval(interval2);
        }
        if (this.expanded === false) {
          this.cutText("cus_text");
          this.cutText("integrations_text");
          this.cutText("manager_text");
          this.cutText("cus_logout");
        } else {
          this.insertText("cus_text", "Customers");
          this.insertText("integrations_text", "CAD");
          this.insertText("cus_logout", "Logout");
          this.insertTextLog("manager_text", "Acc. Managers");
        }
      }, 27);

      this.changeSideNavSizeAndImage();
    } else event.preventDefault();
  }

  // Animation to cut text from right to left
  cutText(id: string) {
    if (document.getElementById(id).innerText.length > 0) {
      document.getElementById(id).innerText = document
        .getElementById(id)
        .innerText.slice(0, -1);
    }
  }

  // Animation to load text from left to right
  insertText(id: string, text: string) {
    if (document.getElementById(id).innerText.length != text.length) {
      document.getElementById(id).innerText =
        document.getElementById(id).innerText +
        text.charAt(document.getElementById(id).innerText.length);
    }
  }
  // Animation to load text from left to right
  insertTextLog(id: string, text: string) {
    if (document.getElementById(id).innerText.length != text.length) {
      // Special condition to handle spaces
      if (text.charAt(document.getElementById(id).innerText.length) == " ") {
        document.getElementById(id).innerText =
          document.getElementById(id).innerText + "\u00a0";
      } else {
        document.getElementById(id).innerText =
          document.getElementById(id).innerText +
          text.charAt(document.getElementById(id).innerText.length);
      }
    }
  }

  changeSideNavSizeAndImage() {
    let img = document.getElementById("acetech_image");
    let main_content = document.getElementsByTagName("mat-drawer-content")[0];
    let activeNav = document.getElementsByClassName("active-list-item")[0];
    if (this.expanded === false) {
      main_content.classList.remove("nav-open");
      main_content.classList.add("nav-closed");

      activeNav.classList.add("shrink");

      if (img) {
        img.classList.add("animate__slideOutLeft");
        setTimeout(() => {
          img.classList.add("invisible");
          img.classList.remove("animate__slideOutLeft");

          activeNav.classList.remove("shrink");
        }, 500);
      }
    } else {
      main_content.classList.remove("nav-closed");
      main_content.classList.add("nav-open");

      activeNav.classList.add("expand");

      if (img) {
        img.classList.add("animate__slideInLeft");
        setTimeout(() => {
          img.classList.remove("invisible");
        }, 10);
        setTimeout(() => {
          img.classList.remove("animate__slideInLeft");

          activeNav.classList.remove("expand");
        }, 500);
      }
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize() {
    if (window.innerWidth <= this.smallerScreen) {
      this.expanded = false;
      this.expansionOption = false;
    } else {
      this.expanded = true;
      this.expansionOption = true;
    }
    this.changeSideNavSizeAndImage();
  }
}
