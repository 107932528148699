import { Component, OnInit } from "@angular/core";
import { MatPaginatorIntl, PageEvent } from "@angular/material/paginator";
import { Sort } from "@angular/material/sort";
import { Active, EditCreate, EditCreateType } from "../../constants/enum";
import { CustomPaginator } from "../../util/custom-paginator";
import { CadIntegration } from "../../model/vendor";
import { VendorService } from "../../services/vendor.service";
import { DataSharingService } from "../../services/data-sharing-service";

@Component({
  selector: "app-cad-integrations",
  templateUrl: "./cad-integrations.component.html",
  styleUrls: ["./cad-integrations.component.scss"],
  providers: [{ provide: MatPaginatorIntl, useValue: CustomPaginator() }],
})
export class CadIntegrationsComponent implements OnInit {
  constructor(
    private vendorService: VendorService,
    private dataSharingService: DataSharingService
  ) {}

  displayedColumns = [
    "vendorName",
    "vendorKey",
    "pushUrl",
    "customers",
    "status",
    "actions",
  ];

  sortedData: CadIntegration[];
  defaultData: CadIntegration[];
  resultsLength: number = 0;

  search: string = "";
  status: string[] = [];
  sort: Sort = { active: "vendorName", direction: "asc" };

  statusList: string[] = [Active.active, Active.inactive];

  pageSize = 50;
  pageIndex = 0;

  loading: boolean = true;
  error: boolean = false;

  editCreate: EditCreateType = EditCreate;

  ngOnInit() {
    this.getVendors();
  }

  getVendors() {
    this.sortedData = [];
    this.loading = true;
    this.vendorService.getVendorList().subscribe(
      (data) => {
        this.defaultData = data;
        this.resultsLength = this.defaultData.length;
        this.filterTable();
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        this.error = true;
      }
    );
  }

  filterTable() {
    let dataToBeSorted = this.defaultData;

    // Validate the data
    if (dataToBeSorted.some((value) => value === null)) {
      this.loading = false;
      this.error = true;
      return;
    }

    // Sort on status
    if (
      this.status.includes(Active.active) &&
      this.status.includes(Active.inactive)
    ) {
      dataToBeSorted = this.defaultData;
    } else if (this.status.includes(Active.active)) {
      dataToBeSorted = this.defaultData.filter(
        (element) => element.enabled == true
      );
    } else if (this.status.includes(Active.inactive)) {
      dataToBeSorted = this.defaultData.filter(
        (element) => element.enabled == false
      );
    }

    // Search for specific data
    const search = this.search.toLowerCase();
    dataToBeSorted = dataToBeSorted.filter((element) => {
      return (
        element.vendorName.toLowerCase().includes(search) ||
        element.vendorKey.toLowerCase().includes(search) ||
        element.pushUrl.toLowerCase().includes(search) ||
        element.customers.find((x) => x.customerKey.includes(search))
      );
    });

    // Order Data
    let dataForPagination = this.sortData(this.sort, dataToBeSorted);

    // Paginate Data
    this.sortedData = this.pagination(dataForPagination);

    // Pagination Length
    this.resultsLength = dataForPagination.length;
  }

  pagination(data: CadIntegration[]) {
    return data.slice(
      this.pageIndex * this.pageSize,
      (this.pageIndex + 1) * this.pageSize
    );
  }

  selectSort(sort: Sort) {
    this.sort = sort;
    this.filterTable();
  }

  sortData(sort: Sort, dataToBeSorted: CadIntegration[]) {
    const data = dataToBeSorted.slice();
    if (!sort.active || sort.direction === "") {
      return data;
    }
    return data.sort((a, b) => {
      const isAsc = sort.direction === "asc";
      const isDesc = sort.direction === "desc";
      switch (sort.active) {
        case "vendorName":
          return this.compare(
            a.vendorName.toLowerCase(),
            b.vendorName.toLowerCase(),
            isAsc
          );
        case "vendorKey":
          return this.compare(
            a.vendorKey.toLowerCase(),
            b.vendorKey.toLowerCase(),
            isAsc
          );
        case "pushUrl":
          return this.compare(
            a.pushUrl.toLowerCase(),
            b.pushUrl.toLowerCase(),
            isAsc
          );
        case "status":
          return this.compare(a.enabled, b.enabled, isDesc);
        default:
          return 0;
      }
    });
  }

  compare(
    a: number | string | Date | boolean,
    b: number | string | Date | boolean,
    isAsc: boolean
  ) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  searchTable(search: string) {
    this.search = search;
    this.pageIndex = 0;
    this.filterTable();
  }

  handlePageEvent(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.filterTable();
  }

  shareData(vendorInfo: CadIntegration) {
    this.dataSharingService.setSharedData(vendorInfo);
  }

  exportData() {
    let dateTime = new Date();
    let csvData = this.ConvertToCSV(this.sortedData);
    let blob = new Blob([csvData], { type: "text/csv" });
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement("a");
    a.href = url;
    a.download =
      "customers_" +
      dateTime.getDate() +
      "_" +
      (dateTime.getMonth() + 1) +
      "_" +
      dateTime.getFullYear() +
      ".csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  ConvertToCSV(vendors: CadIntegration[]) {
    let array = typeof vendors != "object" ? JSON.parse(vendors) : vendors;
    let str = "";
    let row = "";
    let ignored = [];

    // Headers
    for (let index in vendors[0]) {
      // Convert each value to string and comma-separated
      if (!ignored.includes(index)) {
        row += index + ",";
      }
    }
    row = row.slice(0, -1);

    // Append Label row with line break
    str += row + "\r\n";
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in array[i]) {
        if (!ignored.includes(index)) {
          let temp: string = array[i][index] + "";
          if (index === "contactInformation") {
            temp = `${array[i][index]["contactName"]} : ${array[i][index]["contactEmail"]} : ${array[i][index]["contactPhone"]}`;
          }
          if (index === "eventTypes") {
            temp = temp.replace(/,/g, ";");
          }
          if (index === "customers") {
            temp = "";
            array[i][index].forEach(function (element) {
              temp += element.customerKey + "; ";
            });
          }
          line += temp + ",";
        }
      }
      str += line + "\r\n";
    }
    return str;
  }
}
