import { Component, OnInit } from "@angular/core";
import { MatPaginatorIntl, PageEvent } from "@angular/material/paginator";
import { Sort } from "@angular/material/sort";
import { CustomPaginator } from "../../util/custom-paginator";
import { TenantService } from "../../services/tenant.service";
import { Customer } from "../../model/customer";
import { MatDialog } from "@angular/material/dialog";
import { Active, EditCreate, EditCreateType } from "../../constants/enum";
import { DataSharingService } from "../../services/data-sharing-service";

@Component({
  selector: "app-customer",
  templateUrl: "./customer.component.html",
  styleUrls: ["./customer.component.scss"],
  providers: [{ provide: MatPaginatorIntl, useValue: CustomPaginator() }],
})
export class CustomerComponent implements OnInit {
  displayedColumns = [
    "customerName",
    "tenantkey",
    "createdDate",
    "applicationAccess",
    "integrationsAccess",
    "status",
    "edit",
  ];

  sortedData: Customer[];
  defaultData: Customer[];
  resultsLength: number = 0;

  search: string = "";
  status: string[] = [];
  sort: Sort = { active: "customerName", direction: "asc" };

  statusList: string[] = [Active.active, Active.inactive];

  dialogResult = "";

  pageSize = 50;
  pageIndex = 0;

  loading: boolean = true;
  error: boolean = false;

  editCreate: EditCreateType = EditCreate;

  constructor(
    public addDialog: MatDialog,
    private tenantService: TenantService,
    private dataSharingService: DataSharingService
  ) {}

  ngOnInit() {
    this.getTenant();
  }

  getTenant() {
    this.sortedData = [];
    this.loading = true;
    this.tenantService.getTenantlist().subscribe(
      (data) => {
        this.loading = false;
        this.defaultData = data;
        this.resultsLength = this.defaultData.length;
        this.filterTable();
      },
      (error) => {
        this.loading = false;
        this.error = true;
      }
    );
  }

  filterTable() {
    let dataToBeSorted = this.defaultData;

    // Sort on status
    if (this.status.includes(Active.active)) {
      dataToBeSorted = this.defaultData.filter(
        (element) => element.status == 1
      );
    } else if (this.status.includes(Active.inactive)) {
      dataToBeSorted = this.defaultData.filter(
        (element) => element.status == 0
      );
    } else {
      dataToBeSorted = this.defaultData;
    }

    // Search for specific data
    dataToBeSorted = dataToBeSorted.filter((element) => {
      return (
        element.name.toLowerCase().includes(this.search.toLowerCase()) ||
        element.tenantKey.toLowerCase().includes(this.search.toLowerCase())
      );
    });

    // Order Data
    let dataForPagination = this.sortData(this.sort, dataToBeSorted);

    // Paginate Data
    this.sortedData = this.pagination(dataForPagination);

    // Pagination length
    this.resultsLength = dataForPagination.length;
  }

  pagination(data: Customer[]) {
    return data.slice(
      this.pageIndex * this.pageSize,
      (this.pageIndex + 1) * this.pageSize
    );
  }

  selectSort(sort: Sort) {
    this.sort = sort;
    this.filterTable();
  }

  sortData(sort: Sort, dataToBeSorted: Customer[]) {
    const data = dataToBeSorted.slice();
    if (!sort.active || sort.direction === "") {
      return data;
    }
    return data.sort((a, b) => {
      const isAsc = sort.direction === "asc";
      const isDesc = sort.direction === "desc";
      switch (sort.active) {
        case "customerName":
          return this.compare(
            a.name.toLowerCase(),
            b.name.toLowerCase(),
            isAsc
          );
        case "tenantkey":
          return this.compare(
            a.tenantKey.toLowerCase(),
            b.tenantKey.toLowerCase(),
            isAsc
          );
        case "createdDate":
          return this.compare(a.createdDate, b.createdDate, isAsc);
        case "status":
          return this.compare(a.status, b.status, isDesc);
        default:
          return 0;
      }
    });
  }

  compare(
    a: number | string | Date | Boolean,
    b: number | string | Date | Boolean,
    isAsc: boolean
  ) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  statusSelect(status: string[]) {
    this.status = status;
    this.filterTable();
  }

  searchTable(search: string) {
    this.search = search;
    this.pageIndex = 0;
    this.filterTable();
  }

  handlePageEvent(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.filterTable();
  }

  exportData() {
    let dateTime = new Date();
    let csvData = this.ConvertToCSV(this.sortedData);
    let blob = new Blob([csvData], { type: "text/csv" });
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement("a");
    a.href = url;
    a.download =
      "customers_" +
      dateTime.getDate() +
      "_" +
      (dateTime.getMonth() + 1) +
      "_" +
      dateTime.getFullYear() +
      ".csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  ConvertToCSV(customers: Customer[]) {
    let array =
      typeof customers != "object" ? JSON.parse(customers) : customers;
    let str = "";
    let row = "";
    let ignored = [
      "address",
      "emailPassword",
      "phone",
      "profileModules",
      "profileType",
      "emailVerified",
    ];

    // Headers
    for (let index in customers[0]) {
      // Convert each value to string and comma-separated
      if (!ignored.includes(index)) {
        row += index + ",";
      }
    }
    row = row.slice(0, -1);

    // Append Label row with line break
    str += row + "\r\n";
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in array[i]) {
        if (!ignored.includes(index)) {
          var temp = array[i][index] + "";

          if (index === "status") {
            if (temp === "0") {
              temp = "Disabled";
            } else if (temp === "1") {
              temp = "Enabled - Billable";
            } else {
              temp = "Enabled - Demo";
            }
          }
          if (index === "stack") {
            temp = array[i]["stack"]["name"];
          }

          // Removing line breaks
          temp = temp.replace(/(\r\n|\n|\r)/gm, "");
          line += temp + ",";
        }
      }
      str += line + "\r\n";
    }
    return str;
  }

  shareData(customer: Customer) {
    this.dataSharingService.setSharedData(customer);
  }
}
