import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Confirmation } from "../../../constants/enum";
import { environment } from "../../../../environments/environment";
@Component({
  templateUrl: "./countdown-dialog.component.html",
  styleUrls: ["./countdown-dialog.component.scss"],
})
export class CountdownDialogComponent {
  constructor(
    public dialog: MatDialogRef<CountdownDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    data: {
      type: string;
    }
  ) {
    dialog.disableClose = true;
    this.type = data.type;
  }

  type: string;

  startCount = environment.clickPreventionTimer;

  editEnabled = false;

  confirmation = Confirmation;

  ngOnInit(): void {
    const interval = setInterval(() => {
      this.startCount -= 1;

      if (this.startCount === 0) {
        this.editEnabled = true;
        clearInterval(interval);
      }
    }, 1000);
  }
}
