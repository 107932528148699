import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginatorIntl, PageEvent } from "@angular/material/paginator";
import { Sort } from "@angular/material/sort";
import { Manager } from "../../model/manager";
import { ManagerDialogComponent } from "./manager-dialog/manager-dialog.component";
import { ManagerService } from "../../services/manager.service";
import { TenantService } from "../../services/tenant.service";
import { Active, EditCreate, Confirmation } from "../../constants/enum";
import { CustomPaginator } from "../../util/custom-paginator";

@Component({
  selector: "app-manager",
  templateUrl: "./manager.component.html",
  styleUrls: ["./manager.component.scss"],
  providers: [{ provide: MatPaginatorIntl, useValue: CustomPaginator() }],
})
export class ManagerComponent implements OnInit {
  constructor(
    public addManagerDialog: MatDialog,
    public managerservice: ManagerService,
    public tenantService: TenantService
  ) {}
  displayedColumns = [
    "firstName",
    "lastName",
    "email",
    "createdAt",
    "status",
    "customers",
    "edit",
  ];

  sortedData: Manager[];
  defaultData: Manager[];
  resultsLength: number = 0;

  search: string = "";
  status: string[] = [];
  sort: Sort = { active: "firstName", direction: "asc" };

  statusList: string[] = [Active.active, Active.inactive];

  dialogResult = "";

  pageSize = 50;
  pageIndex = 0;

  loading: boolean = true;
  error: boolean = false;

  allCustomers: Map<string, string> = new Map();

  ngOnInit() {
    this.getAllCustomers();
    this.getUsers();
  }

  getUsers() {
    this.sortedData = [];
    this.loading = true;
    this.managerservice.getManagerList().subscribe(
      (data) => {
        this.defaultData = data["entity"];
        this.resultsLength = this.defaultData.length;
        this.filterTable();
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        this.error = true;
      }
    );
  }

  getAllCustomers() {
    this.tenantService.getTenantNames().subscribe((data) => {
      // Make lower case for searching later
      for (let [key, value] of Object.entries(data)) {
        key = key.toLowerCase();
        this.allCustomers.set(key, value);
      }
    });
  }

  filterTable() {
    let dataToBeSorted = this.defaultData;

    // Sort on status
    if (
      this.status.includes(Active.active) &&
      this.status.includes(Active.inactive)
    ) {
      dataToBeSorted = this.defaultData;
    } else if (this.status.includes(Active.active)) {
      dataToBeSorted = this.defaultData.filter(
        (element) => element.enabled == true
      );
    } else if (this.status.includes(Active.inactive)) {
      dataToBeSorted = this.defaultData.filter(
        (element) => element.enabled == false
      );
    } else {
      dataToBeSorted = this.defaultData;
    }

    // Check the customer list keys for possible matches
    let possibleCustomers = new Map(
      [...this.allCustomers].filter(([key]) =>
        key.toLowerCase().includes(this.search.toLowerCase())
      )
    );

    // Search for specific data
    dataToBeSorted = dataToBeSorted.filter((element) => {
      return (
        element.firstName.toLowerCase().includes(this.search.toLowerCase()) ||
        element.lastName.toLowerCase().includes(this.search.toLowerCase()) ||
        element.email.toLowerCase().includes(this.search.toLowerCase()) ||
        element.customers.find((x) => x.includes(this.search.toLowerCase())) ||
        this.searchByCustomerName(element.customers, possibleCustomers)
      );
    });

    // Order Data
    let dataForPagination = this.sortData(this.sort, dataToBeSorted);

    // Paginate Data
    this.sortedData = this.pagination(dataForPagination);

    // Pagination Length
    this.resultsLength = dataForPagination.length;
  }

  searchByCustomerName(customers: string[], possible: Map<string, string>) {
    for (let [key, value] of possible.entries()) {
      if (customers.includes(value)) {
        return true;
      }
    }
    return false;
  }

  pagination(data: Manager[]) {
    return data.slice(
      this.pageIndex * this.pageSize,
      (this.pageIndex + 1) * this.pageSize
    );
  }

  selectSort(sort: Sort) {
    this.sort = sort;
    this.filterTable();
  }

  sortData(sort: Sort, dataToBeSorted: Manager[]) {
    const data = dataToBeSorted.slice();
    if (!sort.active || sort.direction === "") {
      return data;
    }
    return data.sort((a, b) => {
      const isAsc = sort.direction === "asc";
      const isDesc = sort.direction === "desc";
      switch (sort.active) {
        case "firstName":
          return this.compare(
            a.firstName.toLowerCase(),
            b.firstName.toLowerCase(),
            isAsc
          );
        case "lastName":
          return this.compare(
            a.lastName.toLowerCase(),
            b.lastName.toLowerCase(),
            isAsc
          );
        case "email":
          return this.compare(
            a.email.toLowerCase(),
            b.email.toLowerCase(),
            isAsc
          );
        case "createdAt":
          return this.compare(a.createdAt, b.createdAt, isAsc);
        case "status":
          return this.compare(a.enabled, b.enabled, isDesc);
        default:
          return 0;
      }
    });
  }

  compare(
    a: number | string | Date | Boolean,
    b: number | string | Date | Boolean,
    isAsc: boolean
  ) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  statusSelect(status: string[]) {
    this.status = status;
    this.filterTable();
  }

  searchTable(search: string) {
    this.search = search;
    this.pageIndex = 0;
    this.filterTable();
  }

  handlePageEvent(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.filterTable();
  }

  addManager() {
    let dialogRef = this.addManagerDialog.open(ManagerDialogComponent, {
      data: { type: EditCreate.create },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === Confirmation.confirm) {
        this.getUsers();
      }
    });
  }

  editManager(element) {
    let dialogRef = this.addManagerDialog.open(ManagerDialogComponent, {
      data: { manager: element, type: EditCreate.edit },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === Confirmation.confirm) {
        this.getUsers();
      }
    });
  }
}
