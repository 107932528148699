import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Inject, Injectable, InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { takeUntil, timeout } from "rxjs/operators";
import { HttpCancelService } from "./services/http-cancel-service.service";
export const DEFAULT_TIMEOUT = new InjectionToken<number>("defaultTimeout");

@Injectable()
export class Interceptor implements HttpInterceptor {
  constructor(
    @Inject(DEFAULT_TIMEOUT) protected defaultTimeout: number,
    private httpCancelService: HttpCancelService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Setting timeout value so bad requests do not get hung for too long
    const timeoutValue = req.headers.get("timeout") || this.defaultTimeout;
    const timeoutValueNumeric = Number(timeoutValue);

    // Requests from keycloak
    if (!req.url.includes("auth/realms")) {
      const authReq = req.clone({
        headers: req.headers
          .set("Content-Type", "application/json")
          .set(
            "Authorization",
            "Bearer " + localStorage.getItem("access_token")
          ),
      });

      return next
        .handle(authReq)
        .pipe(
          timeout(timeoutValueNumeric),
          takeUntil(this.httpCancelService.onCancelPendingRequests())
        );
    } else {
      return next
        .handle(req)
        .pipe(
          timeout(timeoutValueNumeric),
          takeUntil(this.httpCancelService.onCancelPendingRequests())
        );
    }
  }
}
