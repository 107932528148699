import { HttpErrorResponse } from "@angular/common/http";
import { ErrorDialogComponent } from "../protected/common-dialogs/error-dialog/error-dialog.component";
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

@Injectable({
  providedIn: "root",
})
export class ErrorHandlerService {
  constructor(private dialog: MatDialog) {}

  errorHandler(error: HttpErrorResponse): Promise<any> {
    let errorMessage = "Something went wrong!";
    let title = "An error has occured";

    if (error.status == 400) {
      title = "400 Bad Request";
      errorMessage =
        "It seems like there was an error with your request. If this continues to happen, please contact support.";
    }

    if (error.status == 401) {
      title = "401 Unauthorized";
      errorMessage = "You need to be logged in to access this page.";
    }

    if (error.status == 403) {
      title = "403 Forbidden";
      errorMessage =
        "You don't have permission to access this page. If you think this is a mistake, please contact support.";
    }

    if (error.status == 404) {
      title = "404 Not Found";
      errorMessage =
        "Oops! We can't seem to find the content you're looking for.";
    }

    if (error.status == 408) {
      title = "408 Request Timeout";
      errorMessage =
        "It seems your request took too long, try again. If this continues to happen, please contact support.";
    }

    if (error.status == 409) {
      title = "409 Conflict";
      errorMessage = "Something went wrong on our end, please contact support.";
    }

    if (error.status == 429) {
      title = "429 Too Many Requests";
      errorMessage =
        "You're sending too many requests in a short time. Please wait a moment and try again.";
    }

    if (error.status == 500) {
      title = "500 Internal Server Error";
      errorMessage = "Something went wrong on our end, please contact support.";
    }

    if (error.status == 503) {
      title = "503 Service Unavailable";
      errorMessage =
        "Our service is temporarily unavailable, please contact support.";
    }

    if (error.status == 504) {
      title = "503 Gateway Timeout";
      errorMessage =
        "Our server is taking too long to respond. If this continues to happen, please contact support.";
    }

    // Intentional log for debugging any problems that may arise.
    console.error(error);

    this.dialog.open(ErrorDialogComponent, {
      data: {
        title: title,
        message: errorMessage,
      },
    });
    return Promise.reject(error.error.message);
  }
}
