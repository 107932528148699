import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class DataSharingService {
  private sharedData: any;

  constructor() {}

  setSharedData(data: any) {
    this.sharedData = data;
  }

  getSharedData() {
    this.sharedData;
    return this.sharedData;
  }
}
