import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { NgxSpinnerService } from "ngx-spinner";
import { TenantService } from "../../../services/tenant.service";
import { ManagerService } from "../../../services/manager.service";
import { Stack } from "../../../model/stack";
import { MatDialog } from "@angular/material/dialog";
import { Manager } from "../../../model/manager";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { environment } from "../../../../environments/environment";
import { debounceTime } from "rxjs/operators";
import { Subscription } from "rxjs";
import { EditCreate, Confirmation } from "../../../constants/enum";
import { DEBOUNCE_TIME_SEARCH_FIELDS } from "../../../constants/common";

@Component({
  templateUrl: "./manager-dialog.component.html",
  styleUrls: ["./manager-dialog.component.scss"],
})
export class ManagerDialogComponent implements OnInit, OnDestroy {
  constructor(
    public managerDialog: MatDialogRef<ManagerDialogComponent>,
    private spinner: NgxSpinnerService,
    private tenantservice: TenantService,
    private managerservice: ManagerService,
    public confirmDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data: { manager: Manager; type: string }
  ) {
    this.manager = data.manager;
    this.type = data.type;
  }

  subscription: Subscription;

  stacks: Stack[];

  manager: Manager;
  type: string;

  defaultEmail: string = "";
  uniqueCustomerEmail: boolean = true;

  loading: boolean = true;

  managerForm = new FormGroup({
    firstName: new FormControl("", [Validators.required]),
    lastName: new FormControl("", [Validators.required]),
    email: new FormControl("", [Validators.required, Validators.email]),
    customers: new FormControl([]),
    enabled: new FormControl(true),
    guid: new FormControl(""),
  });

  selectedList: Map<string, string> = new Map();

  deselectedList: Map<string, string> = new Map();

  originalSelect: Map<string, string> = new Map();

  originalDeselect: Map<string, string> = new Map();

  deselectedListToBeMoved: Map<string, string> = new Map();
  selectedListToBeMoved: Map<string, string> = new Map();

  allCustomers: Map<string, string> = new Map();

  selectAllCus: boolean = false;
  deselectAllCus: boolean = false;

  defaultCustomerExists: boolean = false;
  defaultKey: string;
  defaultValue: string;

  displayName = environment.defaultCustomerName;

  tooManyCustomers: boolean = false;

  ngOnInit() {
    this.getAllCustomers();
    if (this.type === EditCreate.edit) {
      this.managerForm.get("firstName").setValue(this.manager.firstName);
      this.managerForm.get("lastName").setValue(this.manager.lastName);
      this.managerForm.get("email").setValue(this.manager.email);
      this.managerForm.get("customers").setValue(this.manager.customers);
      this.managerForm.get("enabled").setValue(this.manager.enabled);
      this.managerForm.get("guid").setValue(this.manager.guid);
      this.defaultEmail = this.manager.email;
    }

    this.subscription = this.managerForm
      .get("email")
      .valueChanges.pipe(debounceTime(DEBOUNCE_TIME_SEARCH_FIELDS))
      .subscribe(() => this.checkCustomerEmail());
  }

  onSubmit() {
    if (
      this.uniqueCustomerEmail &&
      this.managerForm.valid &&
      this.defaultCustomerExists &&
      !this.tooManyCustomers
    ) {
      // Guarenteeing the acetech customer is added before submission
      // Form cannot be submitted if the acetech customer does not exist
      let data = Array.from(this.originalSelect.values());
      data.unshift(environment.defaultCustomerKey);
      this.managerForm.get("customers").setValue(data);

      this.spinner.show();

      if (this.type === EditCreate.create) {
        // Create
        this.managerservice
          .createManager(JSON.stringify(this.managerForm.value))
          .subscribe(
            (data) => {
              this.spinner.hide();
              this.managerDialog.close(Confirmation.confirm);
            },
            (error) => {
              this.spinner.hide();
            }
          );
      } else {
        // Edit
        this.managerservice
          .editmanager(JSON.stringify(this.managerForm.value))
          .subscribe(
            (data) => {
              this.spinner.hide();
              this.managerDialog.close(Confirmation.confirm);
            },
            (error) => {
              this.spinner.hide();
            }
          );
      }
    } else this.managerForm.markAllAsTouched();
  }

  checkCustomerEmail() {
    // empty email string causes error
    let email = this.managerForm.get("email").value;
    if (email) {
      this.managerservice.checkEmail(email).subscribe((data) => {
        // Back end search is case sensitive.
        if (
          data["entity"] &&
          email.toLowerCase() != this.defaultEmail.toLowerCase()
        ) {
          this.uniqueCustomerEmail = false;
        } else {
          this.uniqueCustomerEmail = true;
        }
      });
    }
  }

  getErrorMessage(name: string) {
    switch (name) {
      case "firstName": {
        if (
          this.managerForm.controls["firstName"].hasError("required") &&
          this.managerForm.controls["firstName"].touched
        ) {
          return "You must enter a first name.";
        }
        return "Error";
      }
      case "lastName": {
        if (
          this.managerForm.controls["lastName"].hasError("required") &&
          this.managerForm.controls["lastName"].touched
        ) {
          return "You must enter a last name.";
        }
        return "Error";
      }
      case "email": {
        if (!this.uniqueCustomerEmail) {
          return "A user already exists with that email.";
        }
        if (
          this.managerForm.controls["email"].hasError("required") &&
          this.managerForm.controls["email"].touched
        ) {
          return "You must enter an email.";
        }
        if (
          this.managerForm.controls["email"].hasError("email") &&
          this.managerForm.controls["email"].touched
        ) {
          return "You must enter a valid email.";
        }
        return "Error";
      }
      case "customers": {
        if (!this.defaultCustomerExists) {
          return (
            environment.defaultCustomerName +
            " does not exist. Contact support@acetech.com"
          );
        }
        if (this.tooManyCustomers) {
          return "Too many customers assigned.";
        }
      }
    }
    return null;
  }

  getAllCustomers() {
    this.tenantservice.getTenantNames().subscribe(
      (data) => {
        this.allCustomers = new Map(Object.entries(data));
        this.splitCustomerList(this.allCustomers);
        this.loading = false;
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  // This orders the maps..... Some how
  asIsOrder(a, b) {
    return 1;
  }

  splitCustomerList(customers: Map<string, string>) {
    // Key -> Full Name
    // Value -> Customer Key
    // E.g. {'Gary Kelly'=> 'gk'}

    for (let [key, value] of customers) {
      if (value == environment.defaultCustomerKey) {
        // Acetech default customer
        this.defaultCustomerExists = true;
        this.defaultKey = key;
        this.defaultValue = value;
      } else if (this.managerForm.get("customers").value.includes(value)) {
        // Populating selected customers
        this.selectedList.set(key, value);
        this.originalSelect.set(key, value);
      } else {
        // Populating unselected customers
        this.deselectedList.set(key, value);
        this.originalDeselect.set(key, value);
      }
    }

    // Order the maps
    this.deselectedList = new Map(
      [...this.deselectedList].sort((a, b) =>
        String(a[0].toLowerCase()).localeCompare(b[0].toLowerCase())
      )
    );

    this.originalDeselect = new Map(
      [...this.originalDeselect].sort((a, b) =>
        String(a[0].toLowerCase()).localeCompare(b[0].toLowerCase())
      )
    );

    this.originalSelect = new Map(
      [...this.originalSelect].sort((a, b) =>
        String(a[0].toLowerCase()).localeCompare(b[0].toLowerCase())
      )
    );

    this.selectedList = new Map(
      [...this.selectedList].sort((a, b) =>
        String(a[0].toLowerCase()).localeCompare(b[0].toLowerCase())
      )
    );
  }

  selectCheck(event: boolean, key: string, value: string) {
    if (event) {
      if (!this.selectedListToBeMoved.has(key)) {
        this.selectedListToBeMoved.set(key, value);
      }
    } else if (this.selectedListToBeMoved.has(key)) {
      this.selectedListToBeMoved.delete(key);
    }
  }

  deselectCheck(event: boolean, key: string, value: string) {
    if (event) {
      if (!this.deselectedListToBeMoved.has(key)) {
        this.deselectedListToBeMoved.set(key, value);
      }
    } else if (this.deselectedListToBeMoved.has(key)) {
      this.deselectedListToBeMoved.delete(key);
    }
  }

  selectCustomer(selectAll: MatCheckboxChange) {
    selectAll.checked = false;

    for (let [key, value] of this.deselectedListToBeMoved) {
      this.deselectedList.delete(key);
      this.originalDeselect.delete(key);

      this.selectedList.set(key, value);
      this.originalSelect.set(key, value);
    }

    this.originalSelect = new Map(
      [...this.originalSelect].sort((a, b) =>
        String(a[0].toLowerCase()).localeCompare(b[0].toLowerCase())
      )
    );

    this.selectedList = new Map(
      [...this.selectedList].sort((a, b) =>
        String(a[0].toLowerCase()).localeCompare(b[0].toLowerCase())
      )
    );
    this.deselectedListToBeMoved.clear();
    this.validateSelectedCustomersLength(this.selectedList);
  }

  deselectCustomer(deselectAll: MatCheckboxChange) {
    deselectAll.checked = false;

    for (let [key, value] of this.selectedListToBeMoved) {
      this.selectedList.delete(key);
      this.originalSelect.delete(key);

      this.deselectedList.set(key, value);
      this.originalDeselect.set(key, value);
    }

    this.originalDeselect = new Map(
      [...this.originalDeselect].sort((a, b) =>
        String(a[0].toLowerCase()).localeCompare(b[0].toLowerCase())
      )
    );

    this.deselectedList = new Map(
      [...this.deselectedList].sort((a, b) =>
        String(a[0].toLowerCase()).localeCompare(b[0].toLowerCase())
      )
    );
    this.selectedListToBeMoved.clear();
    this.validateSelectedCustomersLength(this.selectedList);
  }

  searchSelected(search: string) {
    this.selectedList = new Map(
      [...this.originalSelect].filter(([key]) =>
        key.toLowerCase().includes(search.toLowerCase())
      )
    );
  }

  searchDeselected(search: string) {
    this.deselectedList = new Map(
      [...this.originalDeselect].filter(([key]) =>
        key.toLowerCase().includes(search.toLowerCase())
      )
    );
  }

  selectAll(enable: boolean, name: string) {
    let list = document.querySelectorAll("[id*='" + name + "']");
    list.forEach((element) => {
      if (!element.innerHTML.includes("mat-mdc-checkbox-checked") && enable) {
        document.getElementById(element.id).click();
      } else if (
        element.innerHTML.includes("mat-mdc-checkbox-checked") &&
        !enable
      ) {
        document.getElementById(element.id).click();
      }
    });
  }

  validateSelectedCustomersLength(customers: Map<string, string>) {
    // key length of default customer and +1 for the comma
    let keycloakAttributeLength: number = this.displayName.length + 1;
    for (let customer of customers.values()) {
      // key length and +1 for the comma
      keycloakAttributeLength = keycloakAttributeLength + customer.length + 1;
    }

    // 255 is the max length the KC attribute can be
    this.tooManyCustomers = (keycloakAttributeLength > 255);
  }

  close() {
    this.managerDialog.close(Confirmation.cancel);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
