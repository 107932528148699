import { Component, OnInit } from "@angular/core";
import buildInfo from "../build";
import { ActivationEnd } from "@angular/router";
import { HttpCancelService } from "./services/http-cancel-service.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  buildInfo = buildInfo;

  constructor(
    public httpCancelService: HttpCancelService,
    public router: Router
  ) {}

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof ActivationEnd) {
        this.httpCancelService.cancelPendingRequests();
      }
    });
  }
}
