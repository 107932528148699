import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { catchError } from "rxjs/operators";
import { FleetManager } from "../model/manager";
import { Observable } from "rxjs";
import { ErrorHandlerService } from "./response-utils.service";

@Injectable()
export class ManagerService {
  private URL: string = environment.apiUrl + "/management";

  constructor(
    private http: HttpClient,
    private errorHandler: ErrorHandlerService
  ) {}

  getManagerList() {
    let url = this.URL + "/account-manager/list";
    return this.http
      .get(url)
      .pipe(
        catchError((error: HttpErrorResponse) =>
          this.errorHandler.errorHandler(error)
        )
      );
  }

  createManager(jsonData: string) {
    return this.http
      .post(this.URL + "/account-manager", jsonData)
      .pipe(
        catchError((error: HttpErrorResponse) =>
          this.errorHandler.errorHandler(error)
        )
      );
  }

  editmanager(jsonData: string) {
    return this.http
      .put(this.URL + "/account-manager", jsonData)
      .pipe(
        catchError((error: HttpErrorResponse) =>
          this.errorHandler.errorHandler(error)
        )
      );
  }

  checkEmail(email: string) {
    const url = this.URL + "/user/verifyEmail/" + email;
    return this.http
      .get(url)
      .pipe(
        catchError((error: HttpErrorResponse) =>
          this.errorHandler.errorHandler(error)
        )
      );
  }

  getFleetManagersForCustomer(customerKey: string) {
    const url = this.URL + "/fleet-manager/list/" + customerKey;
    return this.http
      .get(url)
      .pipe(
        catchError((error: HttpErrorResponse) =>
          this.errorHandler.errorHandler(error)
        )
      );
  }
}
