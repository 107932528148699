import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { AppRoutingModule } from "./app-routing.module";
import { ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  DatePipe,
  HashLocationStrategy,
  LocationStrategy,
} from "@angular/common";
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";

// Components
import { AppComponent } from "./app.component";
import { Navigation } from "./protected/navigation/navigation.component";
import { CustomerComponent } from "./protected/customer/customer.component";
import { ManagerComponent } from "./protected/manager/manager.component";
import { CadIntegrationsComponent } from "./protected/cad-integrations/cad-integrations.component";
import { AddEditCadIntegrationComponent } from "./protected/cad-integrations/add-edit-cad-integration/add-edit-cad-integration.component";
import { ManagerDialogComponent } from "./protected/manager/manager-dialog/manager-dialog.component";
import { AddEditCustomerComponent } from "./protected/customer/add-edit-customer/add-edit-customer.component";

// Common Dialogs
import { SuccessDialogComponent } from "./protected/common-dialogs/success-dialog/success-dialog.component";
import { WarningDialogComponent } from "./protected/common-dialogs/warning-dialog/warning-dialog.component";
import { ErrorDialogComponent } from "./protected/common-dialogs/error-dialog/error-dialog.component";
import { CountdownDialogComponent } from "./protected/common-dialogs/countdown-dialog/countdown-dialog.component";

// Guards
import { AuthGuard } from "./guard/auth.guard";
import { RoleGuard } from "./guard/role.guard";
import { IntegrationsGuard } from "./guard/route.guard";

// Services
import { TenantService } from "./services/tenant.service";
import { ManagerService } from "./services/manager.service";
import { VendorService } from "./services/vendor.service";
import { DataSharingService } from "./services/data-sharing-service";

// Angualr-Oauth2-odic
import { AuthConfigModule } from "../app/auth.config.module";

// Intercptor
import { DEFAULT_TIMEOUT, Interceptor } from "./interceptor";

// Material
import { MaterialModule } from "./material.module";

// HTTP Cancel
import { HttpCancelService } from "./services/http-cancel-service.service";

// Pipes
import { TooltipCustomerInfoPipe } from "./util/tooltip.pipe";

@NgModule({
  declarations: [
    AppComponent,
    Navigation,
    CustomerComponent,
    AddEditCustomerComponent,
    CountdownDialogComponent,
    ManagerComponent,
    ManagerDialogComponent,
    CadIntegrationsComponent,
    AddEditCadIntegrationComponent,
    SuccessDialogComponent,
    WarningDialogComponent,
    ErrorDialogComponent,
    TooltipCustomerInfoPipe,
  ],
  imports: [
    AuthConfigModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    NgxMatSelectSearchModule,
  ],
  providers: [
    AuthGuard,
    IntegrationsGuard,
    DatePipe,
    TenantService,
    ManagerService,
    VendorService,
    HttpCancelService,
    DataSharingService,
    AuthGuard,
    RoleGuard,
    { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: DEFAULT_TIMEOUT, useValue: 90000 },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
