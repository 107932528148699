import { MatPaginatorIntl } from "@angular/material/paginator";

export function CustomPaginator() {
  const customPaginatorIntl = new MatPaginatorIntl();

  customPaginatorIntl.itemsPerPageLabel = "Rows:";
  customPaginatorIntl.nextPageLabel = "";
  customPaginatorIntl.previousPageLabel = "";

  return customPaginatorIntl;
}
